import React, { useState } from "react";

import LessonLayout from "~/layouts/lesson";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        color={"ms"}
        current_step={2}
        lesson={"Lesson 3"}
        module_title="Will You Buy What They Are Selling?"
        total_count={8}
      >
        <ContentWrapper>
          <H2 underline>Step 2</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>A goal of advertising is to get more people to buy the company's product or to believe in the cause they are promoting, often by using specific strategies.</p>
              <p>In the next steps, you and your teammates will learn about one of these strategies and then share with your classmates.</p>
              <p>Your teacher will assign a number to your team. Click on your number below and follow the directions.</p>

              <div className="flex flex-wrap">
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/files/hs/lesson_3/pursuasion_strategy_1.aw.pdf"
                  icon="download"
                  style={{ width: "152px" }}
                  title="Pursuasion Strategy 1"
                  >
                    1
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/files/hs/lesson_3/pursuasion_strategy_2.aw.pdf"
                  icon="download"
                  style={{ width: "152px" }}
                  title="Pursuasion Strategy 2"
                  >
                    2
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/files/hs/lesson_3/pursuasion_strategy_3.aw.pdf"
                  icon="download"
                  style={{ width: "152px" }}
                  title="Pursuasion Strategy 3"
                  >
                    3
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/files/hs/lesson_3/pursuasion_strategy_4.aw.pdf"
                  icon="download"
                  style={{ width: "152px" }}
                  title="Pursuasion Strategy 4"
                  >
                    4
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/files/hs/lesson_3/pursuasion_strategy_5.aw.pdf"
                  icon="download"
                  style={{ width: "152px" }}
                  title="Pursuasion Strategy 5"
                  >
                    5
                </Button>
                <Button
                  action="secondary"
                  className="m-2"
                  color="purple"
                  href="/files/hs/lesson_3/pursuasion_strategy_6.aw.pdf"
                  icon="download"
                  style={{ width: "152px" }}
                  title="Pursuasion Strategy 6"
                  >
                    6
                </Button>
              </div>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Have students work in teams of 3-4 for this step. Assign each team a number from 1 to 6. Teams will read about strategies used by advertisers to persuade people to buy products or services or to donate to a cause. After reading the information, teams will develop a role-play to present to the class. The role-play should demonstrate the persuasion technique (rather than a mini lecture about the topic). Students can use the example provided or they can write their own role-play. As students perform their role-plays, encourage other teams to try to guess the persuasion strategy being depicted.</p>

              <p>Learning the names of the persuasion strategies is not important. It is more important that students recognize that ads are designed to influence their thoughts and behaviors. Students may question whether persuasion strategies are always a negative thing. This could be a good point of discussion. The strategies can be helpful for marketers while also presenting good information for consumers. It depends on how the strategies are used.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
